import './Dataset.css';
import React, { useState, useEffect } from 'react';
import { FaTable, FaTrash, FaSearch } from 'react-icons/fa';
import Collapsible from '../collapsible/Collapsible';
import DatasetButtons from './DatasetButtons';
import ImportFileForm from './ImportFileForm';
import DeleteButton from './DeleteButton';
import {
  useTrajectoryStore,
  type DatasetFile,
  type Dataset,
} from '../../../../stores/trajectoryStore';

const Dataset: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    datasets,
    fetchDatasets,
    createDataset,
    deleteDataset,
    deleteFile,
    updateDatasetName,
    addFile,
    activeDataset,
    setActiveDataset,
    clearActiveTrajectories,
  } = useTrajectoryStore();
  const [showImportForm, setShowImportForm] = useState(false);
  const fetchTrajectory = useTrajectoryStore((state) => state.fetchTrajectory);
  const loadTrajectory = useTrajectoryStore((state) => state.loadTrajectory);
  const [clickedDatasetId, setClickedDatasetId] = useState<string>('');

  useEffect(() => {
    fetchDatasets();
  }, [fetchDatasets]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredDatasets = datasets
    .filter((dataset) => {
      if (!dataset.files) return false;
      const datasetNameMatch = dataset.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const filesMatch = dataset.files.some((file) =>
        file.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return datasetNameMatch || filesMatch;
    })
    .map((dataset) => ({
      ...dataset,
      files: searchTerm
        ? dataset.files.filter((file) =>
            file.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : dataset.files,
    }));

  const handleDelete = async (datasetIndex: number, fileId: string) => {
    const datasetId = datasets[datasetIndex].id;
    if (!datasetId) return;
    await deleteFile(datasetId, fileId);
  };

  const handleAddDataset = async () => {
    const newDatasetName = `Dataset ${datasets.length + 1}`;
    setActiveDataset(datasets.length.toString());
    const datasetID = await createDataset(newDatasetName);

    if (!datasetID) {
      setActiveDataset('');
    }
  };

  const handleRemoveDataset = async () => {
    const dataset = datasets.find((d) => d.id === activeDataset);
    if (activeDataset !== null && dataset) {
      await deleteDataset(dataset.id);
      setActiveDataset('');
      clearActiveTrajectories();
    }
  };

  const handleAddFile = async () => {
    setShowImportForm(true);
  };

  const handleUploadComplete = (
    file: File,
    trajectoryCdnUrl: string,
    videoCdnUrl: string,
    score: number,
    datasetId: string,
    jobId: string
  ) => {
    const newFile: DatasetFile = {
      name: file.name,
      id: crypto.randomUUID(),
      trajectoryCdnUrl: trajectoryCdnUrl,
      videoCdnUrl: videoCdnUrl,
      score: score,
      jobId: jobId,
    };
    loadTrajectory(trajectoryCdnUrl, videoCdnUrl);
    addFile(datasetId, newFile);
    setShowImportForm(false);
  };

  const handleTitleChange = async (
    datasetIndex: number,
    oldName: string,
    newName: string
  ) => {
    const dataset = datasets[datasetIndex];
    setActiveDataset(datasetIndex.toString());
    await updateDatasetName(dataset.id, newName);
  };

  return (
    <div className="dataset-navigator">
      <DatasetButtons addDataset={handleAddDataset} addFile={handleAddFile} />
      {showImportForm ? (
        <ImportFileForm
          onClose={() => setShowImportForm(false)}
          onImport={handleUploadComplete}
        />
      ) : (
        <>
          <div className="search-bar">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search files..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          {filteredDatasets.map((dataset, datasetIndex) => (
            <Collapsible
              key={dataset.id}
              title={dataset.name}
              onClick={() => {
                setActiveDataset(dataset.id.toString());
                setClickedDatasetId(dataset.id);
              }}
              headerButton={
                <DeleteButton
                  elementId={dataset.name}
                  onDelete={handleRemoveDataset}
                />
              }
              editableTitle={true}
              onTitleChange={(newTitle) =>
                handleTitleChange(datasetIndex, dataset.name, newTitle)
              }
              onFocusHighlight={true}
              isActive={clickedDatasetId === dataset.id}
            >
              {dataset.files.map((file) => (
                <div key={file.id} className="file-row">
                  <div className="file-row-top">
                    <span className="file-name">{file.name}</span>
                    <div className="file-actions">
                      <button
                        className="icon-button"
                        onClick={() => fetchTrajectory(file.jobId, file.id)}
                      >
                        <FaTable />
                      </button>
                      <button
                        className="icon-button"
                        onClick={() => handleDelete(datasetIndex, file.id)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                  <div className="file-row-bottom">
                    <span className="file-score">{file.score?.toFixed(3)}</span>
                  </div>
                </div>
              ))}
            </Collapsible>
          ))}
        </>
      )}
    </div>
  );
};

export default React.memo(Dataset);
