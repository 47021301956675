import React, { useCallback } from 'react';
import { FaFolderPlus, FaFileMedical } from 'react-icons/fa';
import './DatasetButtons.css';

interface DatasetButtonsProps {
  addDataset: () => void;
  addFile: () => void;
}

const DatasetButtons: React.FC<DatasetButtonsProps> = ({
  addDataset,
  addFile,
}) => {
  const handleAddDataset = useCallback(() => addDataset(), [addDataset]);
  const handleAddFile = useCallback(() => addFile(), [addFile]);

  return (
    <div className="dataset-buttons">
      <button
        className="dataset-button"
        onClick={handleAddDataset}
        data-cy={'create-dataset'}
      >
        <FaFolderPlus />
      </button>
      <button
        className="dataset-button"
        onClick={handleAddFile}
        data-cy={'add-file'}
      >
        <FaFileMedical />
      </button>
    </div>
  );
};

export default DatasetButtons;
