import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SecureApi } from '../services/Api';
import { Trajectories } from '../views/editor/visualizer/types';
import { AxiosResponse } from 'axios';
import { VisualMode } from '../utils/types';

export interface DatasetFile {
  name: string;
  id: string; // id refers to...
  videoCdnUrl: string;
  trajectoryCdnUrl: string;
  score: number;
  jobId: string;
}

export interface Dataset {
  id: string;
  name: string;
  files: DatasetFile[];
}

interface CreateDatasetResponse {
  datasetID: string;
}

interface ApiResponse<T> {
  data: T;
  status: number;
}

interface DeleteResponse {
  status: number;
}

interface TrajectoryStore {
  userId: string;
  visualMode: string;
  activeTrajectories: Trajectories;
  trajectories: Trajectories;
  datasets: Dataset[];
  activeDataset: string;
  activeDatasetIndex: number;
  activeVideoUrl: string;
  activeJobId: string;
  setActiveDataset: (id: string) => void;
  setVisualMode: (mode: VisualMode) => void;
  setTrajectories: (trajectories: Trajectories) => void;
  fetchTrajectory: (jobId: string, fileId: string) => Promise<void>;
  loadTrajectory: (
    trajectoryCdnUrl: string,
    videoCdnUrl: string
  ) => Promise<void>;
  clearActiveTrajectories: () => void;
  setActiveVideoUrl: (url: string) => void;
  fetchDatasets: () => Promise<void>;
  createDataset: (datasetName: string) => Promise<string | null>;
  deleteDataset: (datasetId: string) => Promise<void>;
  deleteFile: (datasetId: string, fileId: string) => Promise<void>;
  updateDatasetName: (datasetId: string, newName: string) => Promise<boolean>;
  addFile: (datasetIndex: string, file: DatasetFile) => void;
  setJobId: (jobId: string) => void;
  setUserId: (userId: string) => void;
}

export const useTrajectoryStore = create<TrajectoryStore>()(
  devtools((set, get) => ({
    userId: '',
    visualMode: '3d',
    trajectories: [],
    activeTrajectories: [],
    datasets: [],
    activeDataset: '',
    activeDatasetIndex: 0,
    activeVideoUrl: '',
    activeJobId: '',
    setActiveDataset: (id: string) => {
      const datasetIndex = get().datasets.findIndex(
        (dataset) => dataset.id === id
      );
      set({
        activeDataset: id,
        activeDatasetIndex: datasetIndex,
      });
    },
    setVisualMode: (mode: VisualMode) => set({ visualMode: mode }),
    setTrajectories: (trajectories: Trajectories) => set({ trajectories }),
    fetchTrajectory: async (jobId: string, fileId: string) => {
      try {
        const userId = get().userId;
        const [trajectoryResponse, videoResponse] = await Promise.all([
          SecureApi.get<{ data: { trajectoryCdnUrl: string | null } }>(
            `/v0/data/get-trajectory?video_metadata_id=${fileId}`
          ),
          SecureApi.get<AxiosResponse<{ cdnUrl: string }>>(
            `/v0/data/video-url/${jobId}/${fileId}`
          ),
        ]);

        if (!trajectoryResponse.data.trajectoryCdnUrl) {
          set({ trajectories: [], activeTrajectories: [], activeVideoUrl: '' });
          return;
        }

        const jsonResponse = await fetch(
          trajectoryResponse.data.trajectoryCdnUrl
        );

        const trajectoryData = await jsonResponse.json();

        set({
          trajectories: [...get().trajectories, trajectoryData],
          activeTrajectories: [trajectoryData],
          activeVideoUrl: videoResponse.data.cdnUrl,
        });
      } catch (error) {
        console.error('Error fetching trajectory and video:', error);
        set({ trajectories: [], activeTrajectories: [], activeVideoUrl: '' });
      }
    },
    loadTrajectory: async (trajectoryCdnUrl: string, videoCdnUrl: string) => {
      try {
        const jsonResponse = await fetch(trajectoryCdnUrl);
        const trajectoryData = await jsonResponse.json();

        set({
          activeTrajectories: [trajectoryData],
          activeVideoUrl: videoCdnUrl,
        });
      } catch (error) {
        console.error('Error loading trajectory:', error);
        set({ activeTrajectories: [], activeVideoUrl: '' });
      }
    },
    clearActiveTrajectories: () => set({ activeTrajectories: [] }),
    setActiveVideoUrl: (url: string) => set({ activeVideoUrl: url }),
    fetchDatasets: async () => {
      try {
        const response =
          await SecureApi.get<AxiosResponse<{ datasets: Dataset[] }>>(
            '/v0/data/datasets'
          );
        const datasets = response.data.datasets.map((dataset) => ({
          ...dataset,
          files: dataset.files || [],
        }));

        set({ datasets });
      } catch (error) {
        console.error('Error fetching datasets:', error);
        set({ datasets: [] });
      }
    },
    createDataset: async (datasetName: string) => {
      try {
        const formData = new FormData();
        formData.append('name', datasetName.trim());

        const response = await SecureApi.post<
          ApiResponse<CreateDatasetResponse>
        >('/v0/data/create-dataset', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.datasetID) {
          const { datasetID } = response.data;
          set((state) => ({
            datasets: [
              ...state.datasets,
              { id: datasetID, name: datasetName.trim(), files: [] },
            ],
          }));
          return datasetID;
        }
        throw new Error('Failed to create dataset');
      } catch (error: any) {
        console.error('Error creating dataset:', {
          message: error.message,
          responseData: error.response?.data,
          responseStatus: error.response?.status,
          details: error.response?.data?.details,
        });
        return null;
      }
    },
    deleteDataset: async (datasetId: string) => {
      try {
        const response = await SecureApi.delete<AxiosResponse<DeleteResponse>>(
          `/v0/data/delete-dataset/${datasetId}`
        );

        if (response.status === 200 || response.status === 204) {
          set((state) => ({
            datasets: state.datasets.filter(
              (dataset) => dataset.id !== datasetId
            ),
          }));
        } else {
          throw new Error(`Unexpected status: ${response.status}`);
        }
      } catch (error: any) {
        console.error('Error deleting dataset:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
          details: error.response?.data?.details,
        });
        throw error;
      }
    },
    deleteFile: async (datasetId: string, fileId: string) => {
      try {
        await SecureApi.delete(`/v0/data/delete-file/${datasetId}/${fileId}`);
        set((state) => ({
          datasets: state.datasets.map((dataset) => {
            if (dataset.id === datasetId) {
              return {
                ...dataset,
                files: dataset.files.filter((file) => file.id !== fileId),
              };
            }
            return dataset;
          }),
          trajectories: [],
          videoUrl: '',
        }));
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    },
    updateDatasetName: async (datasetId: string, newName: string) => {
      try {
        const response = await SecureApi.put<ApiResponse<void>>(
          `/v0/data/update-dataset-name/${datasetId}`,
          {
            new_name: newName,
          }
        );

        if (response.status === 200) {
          set((state) => ({
            datasets: state.datasets.map((dataset) =>
              dataset.id === datasetId ? { ...dataset, name: newName } : dataset
            ),
          }));
          return true;
        }
        return false;
      } catch (error) {
        console.error('Error updating dataset name:', error);
        return false;
      }
    },
    addFile: (datasetId: string, file: DatasetFile) => {
      set((state) => {
        const newDatasets = [...state.datasets];

        const datasetIndex = newDatasets.findIndex(
          (dataset) => dataset.id === datasetId
        );
        if (datasetIndex === -1) {
          console.error(`Dataset with id ${datasetId} not found`);
          return state;
        }

        newDatasets[datasetIndex].files.push(file);
        return { datasets: newDatasets };
      });
    },
    setJobId: (jobId: string) => set({ activeJobId: jobId }),
    setUserId: (userId: string) => set({ userId: userId }),
  }))
);
