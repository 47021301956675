import { useFrame, useThree } from '@react-three/fiber';

// Inner component that uses R3F hooks
function CameraTracker({
  onCameraUpdate,
}: {
  onCameraUpdate: (data: any) => void;
}) {
  const { camera } = useThree();

  useFrame(() => {
    onCameraUpdate({
      position: camera.position.clone(),
      rotation: camera.rotation.clone(),
    });
  });

  return null;
}

// Outer display component
function CameraDisplay({ cameraState }: { cameraState: any }) {
  return (
    <div
      style={{
        position: 'absolute',
        right: '5px',
        bottom: '5px',
        width: '250px',
        color: 'white',
        background: 'rgba(0,0,0,0.7)',
        padding: '8px',
        borderRadius: '5px',
        pointerEvents: 'none',
        fontFamily: 'monospace',
        fontSize: '10px',
        zIndex: 1000,
      }}
    >
      <div>
        Position: [{cameraState.position.x.toFixed(3)},{' '}
        {cameraState.position.y.toFixed(3)}, {cameraState.position.z.toFixed(3)}
        ]
      </div>
      <div>
        Rotation: [{cameraState.rotation.x.toFixed(3)},{' '}
        {cameraState.rotation.y.toFixed(3)}, {cameraState.rotation.z.toFixed(3)}
        ]
      </div>
    </div>
  );
}

export { CameraTracker, CameraDisplay };
