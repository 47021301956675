import * as THREE from 'three';
import { Pose } from './types';
import { TubeGeometry } from 'three';
import { limbMap, leftLimbs, rightLimbs, colorMap } from './PoseLandmarkMap';

/**
 * Renders a set of lines connecting the data points in the provided array, representing a human pose.
 *
 * @param {Object} props - The component props.
 * @param {DataPoint[]} props.points - The array of data points representing the human pose.
 * @returns {JSX.Element} - The rendered lines connecting the data points.
 */
export function Lines({
  points,
  visible,
  opacity,
}: {
  points: Pose;
  visible: Map<number, boolean>;
  opacity: number;
}) {
  const allLimbs = Object.entries(limbMap);

  const isValidPoint = (index: number) => {
    return points[index] && typeof points[index].x === 'number';
  };

  const getLineColor = (i: number, j: number): string => {
    if (
      leftLimbs.some(
        (pair) =>
          (pair[0] === i && pair[1] === j) || (pair[0] === j && pair[1] === i)
      )
    ) {
      return colorMap['right'];
    } else if (
      rightLimbs.some(
        (pair) =>
          (pair[0] === i && pair[1] === j) || (pair[0] === j && pair[1] === i)
      )
    ) {
      return colorMap['left'];
    } else {
      return colorMap['mid'];
    }
  };

  return (
    <>
      {allLimbs.map(([key, [i, j]], index) => {
        if (!isValidPoint(i) || !isValidPoint(j)) {
          return null;
        }

        const start = new THREE.Vector3(points[i].x, points[i].y, points[i].z);
        const end = new THREE.Vector3(points[j].x, points[j].y, points[j].z);

        const curve = new THREE.LineCurve3(start, end);
        const tubeGeometry = new TubeGeometry(curve, 1, 0.015, 8, false);

        return (
          <mesh key={index}>
            <primitive object={tubeGeometry} attach="geometry" />
            <meshBasicMaterial
              attach="material"
              color={getLineColor(i, j)}
              transparent={true}
              opacity={visible.get(Number(key)) ? 1.0 : opacity}
            />
          </mesh>
        );
      })}
    </>
  );
}
