import React from 'react';
import { FaBan } from 'react-icons/fa';
import './DeleteButton.css';

interface DeleteButtonProps {
  elementId: string;
  onDelete: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ elementId, onDelete }) => {
  const name = elementId.toLowerCase().replace(/\s+/g, '-');
  return (
    <button
      className="header-button delete-button"
      onClick={onDelete}
      data-cy={`delete-${name}`}
    >
      <FaBan />
    </button>
  );
};

export default DeleteButton;
