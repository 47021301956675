import axios, { AxiosRequestConfig } from 'axios';
import { authorizedEvent, unauthorizedEvent } from '../utils/events';
import { refreshAccessToken } from '../utils/auth';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

/**
 * Adds authentication headers to the request configuration.
 * @param config - The original Axios request configuration.
 * @returns A Promise resolving to the updated Axios request configuration.
 * @throws Error if OAuth token is missing.
 */
async function addAuthHeaders(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  const oauthToken = localStorage.getItem('oauthToken');

  if (!oauthToken) {
    throw new Error('Missing OAuth token');
  }

  const params = new URLSearchParams(config.params || {});
  params.append('oauth_token', oauthToken);

  return {
    ...config,
    params,
  };
}

export const SecureApi = {
  /**
   * Sends a secure GET request with authentication verification.
   * @param url - The endpoint URL.
   * @param config - Optional Axios request configuration.
   * @returns A Promise resolving to the response data.
   */
  async get<T>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
    await refreshAccessToken(() => {});
    const finalConfig = await addAuthHeaders(config);
    return Api.get(url, finalConfig);
  },

  /**
   * Sends a secure POST request with authentication verification.
   * @param url - The endpoint URL.
   * @param data - The request payload.
   * @param config - Optional Axios request configuration.
   * @returns A Promise resolving to the response data.
   */
  async post<T>(
    url: string,
    data: any,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    await refreshAccessToken(() => {});
    const finalConfig = await addAuthHeaders(config);
    return Api.post(url, data, finalConfig);
  },
  /**
   * Sends a secure DELETE request with authentication verification.
   * @param url - The endpoint URL.
   * @param config - Optional Axios request configuration.
   * @returns A Promise resolving to the response data.
   */
  async delete<T>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
    await refreshAccessToken(() => {});
    const finalConfig = await addAuthHeaders(config);
    return Api.delete(url, finalConfig);
  },
  /**
   * Sends a secure PUT request with authentication verification.
   * @param url - The endpoint URL.
   * @param data - The request payload.
   * @param config - Optional Axios request configuration.
   * @returns A Promise resolving to the response data.
   */
  async put<T>(
    url: string,
    data: any,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    await refreshAccessToken(() => {});
    const finalConfig = await addAuthHeaders(config);
    return Api.put(url, data, finalConfig);
  },
};

/**
 * Converts a string from snake_case or kebab-case to camelCase.
 * @param str - The input string to convert.
 * @returns The converted camelCase string.
 */
function toCamelCase(str: string): string {
  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );
}

/**
 * Recursively converts all keys in an object or array from snake_case to camelCase.
 * @param obj - The object or array to convert.
 * @returns The converted object or array with camelCase keys.
 */
function convertKeysToCamelCase(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = toCamelCase(key);
    acc[camelKey] = convertKeysToCamelCase(obj[key]);
    return acc;
  }, {} as any);
}

/**
 * Intercepts API responses to convert snake_case keys to camelCase and handle errors.
 * Successful responses have their data converted, while errors are logged and trigger
 * authorization events based on the status code.
 */
Api.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = convertKeysToCamelCase(response.data);
    }
    return response;
  },
  (error) => {
    console.error('API Error:', error.response);
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      window.dispatchEvent(unauthorizedEvent);
    }
    return Promise.reject(error);
  }
);
