import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { Canvas, extend } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import styles from './Visualizer.module.css';
import ControlPanel from '../controls/ControlPanel';
import { Trajectories } from './types';
import { CameraControls } from './CameraControls';
import { Scene } from './Scene';
import { Floor } from './Floor';
import { useLocation } from 'react-router-dom';
import { CameraTracker, CameraDisplay } from './CameraLogger';
import { limbMap } from './PoseLandmarkMap';
import { useTrajectoryStore } from '../../../stores/trajectoryStore';
import PromptEditor from './video/PromptEditor';

extend({ OrbitControls });

const Visualizer = memo(function Visualizer() {
  const location = useLocation();
  const visualMode = useTrajectoryStore((state) => state.visualMode);
  const [translation, setTranslation] = useState<[number, number, number]>([
    0, 0, 0,
  ]);
  const [rotation, setRotation] = useState<[number, number, number]>([0, 0, 0]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [zMinTrajectory, setZMinTrajectory] = useState(0);
  const [zMaxTrajectory, setZMaxTrajectory] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [showTrajectory, setShowTrajectory] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTrajectoryId, setSelectedTrajectoryId] = useState<number>(0);
  const [totalFrames, setTotalFrames] = useState<number>(0);
  const [visibleLimbs, setVisibleLimbs] = useState<Map<number, boolean>>(
    new Map(Object.keys(limbMap).map((key) => [Number(key), true]))
  );
  const [opacity, setOpacity] = useState<number>(1.0);
  const [hiddenTrajectories, setHiddenTrajectories] = useState<Set<number>>(
    new Set()
  );
  const [cameraState, setCameraState] = useState({
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    zoom: 1,
  });

  const activeTrajectories = useTrajectoryStore(
    (state: { activeTrajectories: Trajectories }) => state.activeTrajectories
  );

  /**
   * Memoize the trajectories prop passed to ControlPanel to prevent unnecessary rerenders.
   */
  const memoizedTrajectories = useMemo(
    () => Array.from(activeTrajectories.keys()).map(Number),
    [activeTrajectories]
  );

  /**
   * Memoize the cameraState prop passed to CameraDisplay to prevent unnecessary rerenders.
   */
  const memoizedCameraState = useMemo(() => cameraState, [cameraState]);

  /**
   * Memoize camera props to prevent unnecessary re-renders of Canvas.
   */
  const memoizedCamera = useMemo(
    () => ({
      position: [0, 3.7904830519488804, -10.988310509260442] as [
        number,
        number,
        number,
      ],
      rotation: [-2.8094184711480428, 0, -3.14159265358979] as [
        number,
        number,
        number,
      ],
      fov: 50,
      zoom: 1,
    }),
    []
  );

  /**
   * Initializes the pose data for all trajectories and sets the minimum and maximum Z values for the
   * 3D scene based on the number of poses in the test data.
   */
  useEffect(() => {
    const fetchTrajectoryData = async () => {
      setIsLoading(true);
      const path = location.pathname;
      const lastPart = path.split('/').pop() || '';

      if (!lastPart) {
        setIsLoading(false);
        return;
      }

      try {
        // Assuming the data is already in the correct format
        setTotalFrames(activeTrajectories.length);

        if (activeTrajectories.length > 0) {
          setTotalFrames(activeTrajectories.length);
          // Set the minimum and maximum Z values based on the number of poses
          const gapSize = 2; // Adjust this value to change the gap size between poses
          const numPoses = activeTrajectories.length;
          const zRange = (numPoses - 1) * gapSize;

          setZMinTrajectory(-zRange / 2);
          setZMaxTrajectory(zRange / 2);
        }
      } catch (error) {
        console.error('Error fetching trajectory data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrajectoryData();
  }, [activeTrajectories.length, location.pathname]);

  /**
   * Consolidated animation effect to handle both translation and current frame updates.
   */
  useEffect(() => {
    let animationFrameId: number | null = null;
    let lastTimestamp: number | null = null;

    const animate = (timestamp: number): void => {
      if (isPlaying) {
        if (lastTimestamp === null) {
          lastTimestamp = timestamp;
        }

        const deltaTime = timestamp - lastTimestamp;
        const frameIncrement = Math.round((playbackSpeed * deltaTime) / 16.67); // 16.67 ms is roughly 60 FPS

        setCurrentFrame((prevFrame: number) => {
          const nextFrame: number = prevFrame + frameIncrement;
          if (nextFrame >= totalFrames) {
            setIsPlaying(false);
            return prevFrame;
          }
          return nextFrame;
        });

        setTranslation((prev: [number, number, number]) => {
          const newTranslation: [number, number, number] = [...prev];
          newTranslation[2] -= playbackSpeed * (deltaTime / 16.67);

          if (newTranslation[2] < zMinTrajectory) {
            setIsPlaying(false);
          }

          return newTranslation;
        });

        lastTimestamp = timestamp;
      }

      if (isPlaying) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(animate);
    }

    return () => {
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isPlaying, zMinTrajectory, playbackSpeed, totalFrames]);

  /**
   * Toggles the visibility of a trajectory.
   * @param {number} trajectoryId - The ID of the trajectory to toggle.
   * @returns {void}
   */
  const handleToggleHide = useCallback((trajectoryId: number) => {
    setHiddenTrajectories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(trajectoryId)) {
        newSet.delete(trajectoryId);
      } else {
        newSet.add(trajectoryId);
      }
      return newSet;
    });
  }, []);

  /**
   * Deletes a trajectory.
   * @param {number} trajectoryId - The ID of the trajectory to delete.
   * @returns {void}
   */
  const handleDelete = useCallback(
    (trajectoryId: number) => {
      setVisibleLimbs((prev) => {
        const newMap = new Map(prev);
        newMap.delete(trajectoryId);
        return newMap;
      });
      if (selectedTrajectoryId === trajectoryId) {
        setSelectedTrajectoryId(0);
      }
    },
    [selectedTrajectoryId]
  );

  /**
   * Handles the click event on a data point in the 3D scene.
   * @param {number} trajectoryId - The ID of the trajectory that was clicked.
   * @returns {void}
   */
  const handlePointClick = useCallback(
    (trajectoryId: number) => {
      setSelectedTrajectoryId(trajectoryId);
      setTotalFrames(activeTrajectories[trajectoryId].length);
    },
    [activeTrajectories]
  );

  /**
   * Handles the selection of a trajectory from the list.
   * @param {number} trajectoryId - The ID of the selected trajectory.
   * @returns {void}
   */
  const handleSelectTrajectory = useCallback(
    (trajectoryId: number) => {
      setSelectedTrajectoryId(trajectoryId);
      setTotalFrames(activeTrajectories[trajectoryId].length);
    },
    [activeTrajectories]
  );

  // Move translations calculation to component level
  const translations = useMemo(
    () =>
      activeTrajectories.map((trajectory, trajectoryIndex) =>
        trajectory.map(
          (_, index) =>
            [
              translation[0] + trajectoryIndex,
              translation[1],
              translation[2] + index * 2,
            ] as [number, number, number]
        )
      ),
    [translation, activeTrajectories]
  );

  const renderedTrajectories = useMemo(() => {
    if (showTrajectory) {
      return activeTrajectories.map(
        (trajectory, trajectoryIndex) =>
          !hiddenTrajectories.has(trajectoryIndex) &&
          trajectory.map((data, index) => (
            <Scene
              key={`${trajectoryIndex}-${index}`}
              data={data}
              translation={translations[trajectoryIndex][index]}
              rotation={rotation}
              trajectoryId={trajectoryIndex}
              onPointClick={handlePointClick}
              visibleLimbs={visibleLimbs}
              limbOpacity={opacity}
            />
          ))
      );
    } else {
      return activeTrajectories.map(
        (trajectory, trajectoryIndex) =>
          !hiddenTrajectories.has(trajectoryIndex) && (
            <Scene
              key={`${trajectoryIndex}`}
              data={trajectory[currentFrame]}
              translation={[0, 0, 0]}
              rotation={rotation}
              trajectoryId={trajectoryIndex}
              onPointClick={handlePointClick}
              visibleLimbs={visibleLimbs}
              limbOpacity={opacity}
            />
          )
      );
    }
  }, [
    showTrajectory,
    activeTrajectories,
    hiddenTrajectories,
    translations,
    rotation,
    handlePointClick,
    visibleLimbs,
    opacity,
    currentFrame,
  ]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div data-cy="visualizer" className={styles.root} tabIndex={0}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {visualMode === '3d' ? (
          <Canvas camera={memoizedCamera}>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} intensity={0.8} />
            <Floor />
            {renderedTrajectories}
            <CameraControls />
            <axesHelper args={[5]} />
            <CameraTracker onCameraUpdate={setCameraState} />
          </Canvas>
        ) : (
          <PromptEditor />
        )}
        <ControlPanel
          trajectories={memoizedTrajectories}
          hiddenTrajectories={hiddenTrajectories}
          onToggleHide={handleToggleHide}
          onDelete={handleDelete}
          onSelectTrajectory={handleSelectTrajectory}
          selectedTrajectoryId={selectedTrajectoryId}
          setVisibleLimbs={setVisibleLimbs}
          setOpacity={setOpacity}
          setCurrentFrame={setCurrentFrame}
        />
        {visualMode === '3d' && (
          <CameraDisplay cameraState={memoizedCameraState} />
        )}
      </div>
    </div>
  );
});

export default Visualizer;
