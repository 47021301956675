import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FaTrash, FaEye, FaEyeSlash } from 'react-icons/fa';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import './TrajectoryList.css';

interface TrajectoryListProps {
  trajectories: number[];
  trajectoryNames: { [key: number]: string };
  hiddenTrajectories: Set<number>;
  selectedTrajectoryId: number;
  onToggleHide: (trajectoryId: number) => void;
  onDelete: (trajectoryId: number) => void;
  onSelectTrajectory: (trajectoryId: number) => void;
  onTrajectoryNameChange: (trajectoryId: number, newName: string) => void;
}

const TrajectoryList: React.FC<TrajectoryListProps> = ({
  trajectories,
  trajectoryNames,
  hiddenTrajectories,
  selectedTrajectoryId,
  onToggleHide,
  onDelete,
  onSelectTrajectory,
  onTrajectoryNameChange,
}) => {
  const [editingTrajectoryId, setEditingTrajectoryId] = useState<number | null>(
    null
  );

  const handleDoubleClick = (trajectoryId: number): void => {
    setEditingTrajectoryId(trajectoryId);
  };

  const handleNameBlur = (): void => {
    setEditingTrajectoryId(null);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === 'Enter') {
      setEditingTrajectoryId(null);
    }
  };

  return (
    <List className="trajectoryList">
      {trajectories.map((trajectoryId) => (
        <ListItem
          key={trajectoryId}
          className={`trajectoryItem ${selectedTrajectoryId === trajectoryId ? 'selectedTrajectory' : ''}`}
          onClick={() => onSelectTrajectory(trajectoryId)}
          onDoubleClick={() => handleDoubleClick(trajectoryId)}
        >
          {editingTrajectoryId === trajectoryId ? (
            <TextField
              value={trajectoryNames[trajectoryId]}
              onChange={(e) =>
                onTrajectoryNameChange(trajectoryId, e.target.value)
              }
              onBlur={handleNameBlur}
              onKeyDown={handleKeyPress}
              style={{ color: '#000', width: '200px' }}
              variant="standard"
              size="small"
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <ListItemText
              disableTypography
              primary={
                <span className="trajectoryName">
                  {trajectoryNames[trajectoryId] ||
                    `Trajectory ${trajectoryId + 1}`}
                </span>
              }
            />
          )}
          <div className="trajectory-actions">
            <button
              className="icon-button"
              onClick={() => onToggleHide(trajectoryId)}
            >
              {hiddenTrajectories.has(trajectoryId) ? (
                <FaEyeSlash />
              ) : (
                <FaEye />
              )}
            </button>
            <button
              className="icon-button"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onDelete(trajectoryId);
              }}
            >
              <FaTrash />
            </button>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

export default TrajectoryList;
