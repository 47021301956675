import React, { useState } from 'react';
import { SecureApi } from '../../../../services/Api';
import { AxiosProgressEvent, AxiosResponse } from 'axios';
import { useTrajectoryStore } from '../../../../stores/trajectoryStore';
import './ImportFileForm.css';

interface ImportFileFormProps {
  onClose: () => void;
  onImport: (
    file: File,
    trajectoryCdnUrl: string,
    videoCdnUrl: string,
    score: number,
    datasetId: string,
    jobId: string
  ) => void;
}

const ImportFileForm: React.FC<ImportFileFormProps> = ({
  onClose,
  onImport,
}) => {
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const {
    datasets,
    activeDataset,
    createDataset,
    setActiveDataset,
    setActiveVideoUrl,
  } = useTrajectoryStore();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const fetchScore = async (videoMetadataId: string): Promise<number> => {
    try {
      const response = await SecureApi.get<AxiosResponse<{ score: number }>>(
        `/v0/data/get-score/${videoMetadataId}`
      );
      if (response.status === 200) {
        return response.data.score;
      }
    } catch (error) {
      console.error('Failed to fetch score:', error);
    }
    return 0.0;
  };

  const getDatasetId = async (): Promise<string> => {
    if (activeDataset !== '') {
      return activeDataset;
    } else {
      console.log('dataset1');
      const newDatasetName = `Dataset ${datasets.length + 1}`;
      console.log('dataset2');
      const newDatasetId = await createDataset(newDatasetName);
      console.log('dataset3');
      if (newDatasetId) {
        console.log('dataset4');
        return newDatasetId;
      }
      console.log('dataset5');
      throw new Error('Failed to create new dataset');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    console.log('here1');
    if (!selectedFile) return;
    console.log('here2');
    setUploading(true);
    console.log('here3');
    try {
      let currentDatasetId;
      try {
        console.log('here4');
        currentDatasetId = await getDatasetId();
        console.log('here5');
      } catch (error) {
        console.log('here6');
        console.error('Failed to get dataset ID:', error);
        setUploading(false);
        console.log('here7');
        return;
      }
      console.log('here8');
      setActiveDataset(currentDatasetId);
      console.log('here9');

      const formData = new FormData();
      formData.append('video', selectedFile);
      formData.append('name', fileName.trim());
      formData.append('dataset', currentDatasetId);
      console.log('here10');
      let uploadResponse;
      try {
        uploadResponse = await SecureApi.post<AxiosResponse<any>>(
          `/v0/upload/video-upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              console.log('here10.5 progressEvent', progressEvent);
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
              );
              setProgress(percentCompleted);
            },
          }
        );
      } catch (error) {
        console.error('Failed to upload video:', error);
        throw error;
      }
      console.log('here11');
      const jobId = uploadResponse.data.jobId;
      const fileUrl = uploadResponse.data.fileUrl;
      const fileHash = uploadResponse.data.fileNameHash;
      setActiveVideoUrl(fileUrl);

      const processFormData = new FormData();
      processFormData.append('name', fileName.trim());
      processFormData.append('dataset', currentDatasetId);
      processFormData.append('file_url', fileUrl);
      processFormData.append('file_name_hash', fileHash);
      processFormData.append('job_id', jobId);

      let processResponse;
      try {
        processResponse = await SecureApi.post<AxiosResponse<any>>(
          `/v0/upload/process-video`,
          processFormData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } catch (error) {
        console.error('Failed to process video:', error);
        throw error;
      }

      const firstUpload = processResponse.data.results[0];
      const trajectoryCdnUrl = firstUpload['trajectoryCdnUrl'];
      const videoCdnUrl = firstUpload['videoCdnUrl'];
      const videoMetadataId = firstUpload['videoMetadataId'];
      console.log('here12');

      if (processResponse.status === 200) {
        console.log('here13');
        const score = await fetchScore(videoMetadataId);
        console.log('here14');
        onImport(
          selectedFile,
          trajectoryCdnUrl,
          videoCdnUrl,
          score,
          currentDatasetId,
          jobId
        );
        console.log('here15');
      }
      console.log('here16');
    } catch (error) {
      console.error('Upload failed:', error);
    } finally {
      console.log('here17');
      setUploading(false);
      setProgress(0);
      setSelectedFile(null);
      console.log('here18');
    }
  };

  return (
    <div className="import-file-form">
      <h2 className="file-name">
        {selectedFile ? selectedFile.name : 'Import File'}
      </h2>

      <form onSubmit={handleSubmit}>
        <label className={`file-input-label ${uploading ? 'disabled' : ''}`}>
          Choose File
          <input
            type="file"
            accept=".mp4"
            onChange={handleFileChange}
            disabled={uploading}
            className="hidden"
          />
        </label>
        <div className="button-group">
          <button
            type="submit"
            disabled={!selectedFile || uploading}
            className="import-button"
          >
            {uploading ? 'Importing...' : 'Import'}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="cancel-button"
            disabled={uploading}
          >
            Cancel
          </button>
        </div>

        {uploading && (
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div
                className="progress-fill"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="progress-text">{progress}% Uploaded</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default ImportFileForm;
