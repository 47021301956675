import React, { useState, useMemo, useCallback } from 'react';
import * as THREE from 'three';
import { Pose } from './types';
import { Point } from './Point';
import { Lines } from './Lines';
import { jointMap, colorMap } from './PoseLandmarkMap';

/**
 * Renders a 3D scene with a set of data points representing a human pose.
 * The scene is translated and rotated based on the provided translation values.
 * The function also handles the highlighting of hovered points and displays their corresponding body part labels.
 *
 * @param {Object} props - The component props.
 * @param {DataPoint[]} props.data - The array of data points representing the human pose.
 * @param {[number, number, number]} props.translation - The translation values for the 3D scene.
 * @returns {JSX.Element} - The rendered 3D scene.
 */
export function Scene({
  data,
  translation,
  rotation,
  trajectoryId,
  onPointClick,
  visibleLimbs,
  limbOpacity,
}: {
  data: Pose;
  translation: [number, number, number];
  rotation: [number, number, number];
  trajectoryId: number;
  onPointClick: (trajectoryId: number) => void;
  visibleLimbs: Map<number, boolean>;
  limbOpacity: number;
}) {
  const [hoveredPoint, setHoveredPoint] = useState<number | null>(null);

  const translatedAndRotatedData = useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    const [rotX, rotY, rotZ] = rotation.map((angle) => (angle * Math.PI) / 180);

    // Find the lowest y-coordinate
    const lowestY = Math.min(...data.map((point) => point.y));

    // Create rotation matrices and apply transformations
    const rotationMatrix = new THREE.Matrix4()
      .makeRotationX(rotX)
      .multiply(new THREE.Matrix4().makeRotationY(rotY))
      .multiply(new THREE.Matrix4().makeRotationZ(rotZ));

    return data.map((point) => {
      const vector = new THREE.Vector3(point.x, point.y - lowestY, point.z);
      vector.applyMatrix4(rotationMatrix);
      return {
        x: vector.x + translation[0],
        y: vector.y + translation[1],
        z: vector.z + translation[2],
        visibility: point.visibility,
        label: point.label,
      };
    });
  }, [data, translation, rotation]);

  const memoizedPositions = useMemo(
    () =>
      translatedAndRotatedData.map(
        (point) => [point.x, point.y, point.z] as [number, number, number]
      ),
    [translatedAndRotatedData]
  );

  const memoizedColors = useMemo(
    () =>
      translatedAndRotatedData.map((_, index) =>
        jointMap[index].startsWith('left')
          ? colorMap['left']
          : jointMap[index].startsWith('right')
            ? colorMap['right']
            : colorMap['mid']
      ),
    [translatedAndRotatedData]
  );

  const memoizedLabels = useMemo(
    () =>
      translatedAndRotatedData.map((_, index) =>
        hoveredPoint === index ? jointMap[index] : ''
      ),
    [translatedAndRotatedData, hoveredPoint]
  );

  const memoizedVisibleLimbs = useMemo(
    () => new Map(visibleLimbs),
    [visibleLimbs]
  );
  const memoizedLimbOpacity = useMemo(() => limbOpacity, [limbOpacity]);

  const handlePointClick = useCallback(() => {
    onPointClick(trajectoryId);
  }, [onPointClick, trajectoryId]);

  const handleSetHoveredPoint = useCallback((index: number | null) => {
    setHoveredPoint(index);
  }, []);

  return (
    <group position={new THREE.Vector3().negate()}>
      {translatedAndRotatedData.map((point, index) => (
        <Point
          key={index}
          position={memoizedPositions[index]}
          color={memoizedColors[index]}
          label={memoizedLabels[index]}
          fontSize={0.5}
          index={index}
          onHover={handleSetHoveredPoint}
          onClick={handlePointClick}
          trajectoryId={trajectoryId}
        />
      ))}
      <Lines
        points={translatedAndRotatedData}
        visible={memoizedVisibleLimbs}
        opacity={memoizedLimbOpacity}
      />
    </group>
  );
}
