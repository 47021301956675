import './ControlPanel.css';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Collapsible from './collapsible/Collapsible';
import TrajectoryList from './trajectory/TrajectoryList';
import PoseControl from './pose/PoseControl';
import { VideoPreview } from './video/VideoPreview';
import VisibilityBar from './visibility/VisibilityBar';
import Dataset from './dataset/Dataset';
import Account from './account/Account';
import Prompt from './prompt/Prompt';

interface ControlPanelProps {
  trajectories: number[];
  hiddenTrajectories: Set<number>;
  onToggleHide: (trajectoryId: number) => void;
  onDelete: (trajectoryId: number) => void;
  onSelectTrajectory: (trajectoryId: number) => void;
  selectedTrajectoryId: number;
  setVisibleLimbs: React.Dispatch<React.SetStateAction<Map<number, boolean>>>;
  setOpacity: React.Dispatch<React.SetStateAction<number>>;
  setCurrentFrame: (frame: number) => void;
}

const ControlPanel: React.FC<ControlPanelProps> = ({
  trajectories,
  hiddenTrajectories,
  onToggleHide,
  onDelete,
  onSelectTrajectory,
  selectedTrajectoryId,
  setVisibleLimbs,
  setOpacity,
  setCurrentFrame,
}) => {
  const [trajectoryNames, setTrajectoryNames] = useState<{
    [key: number]: string;
  }>({});
  const [videoMetadataId, setVideoMetadataId] = useState<string>('');
  const [openSections, setOpenSections] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeSection, setActiveSection] = useState<string>('');
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(true);

  useEffect(() => {
    const path = window.location.pathname;
    const id = path.substring(path.lastIndexOf('/') + 1);
    setVideoMetadataId(id);
  }, []);

  useEffect(() => {
    const initialNames = trajectories.reduce(
      (acc, id) => {
        acc[id] = `Trajectory ${id + 1}`;
        return acc;
      },
      {} as { [key: number]: string }
    );
    setTrajectoryNames(initialNames);
  }, [trajectories]);

  const handleVisibilityToggle = useCallback(() => {
    setIsPanelOpen((prev) => !prev);
  }, [isPanelOpen]);

  const handleSectionToggle = useCallback(
    (title: string, isOpen: boolean) => {
      if (isOpen) {
        setOpenSections((prev) => {
          const next = [...prev, title];
          const scrollHeight = containerRef.current?.scrollHeight ?? 0;
          if (scrollHeight > window.innerHeight - 32) {
            return [title];
          }
          return next;
        });
      } else {
        setOpenSections((prev) => prev.filter((t) => t !== title));
      }
    },
    [containerRef]
  );

  const handleTrajectoryNameChange = useCallback(
    (trajectoryId: number, newName: string) => {
      setTrajectoryNames((prev) => ({
        ...prev,
        [trajectoryId]: newName,
      }));
    },
    []
  );

  return (
    <div
      ref={containerRef}
      data-cy="control-panel"
      className={`controlsContainer ${isPanelOpen ? 'open' : 'closed'}`}
    >
      <VisibilityBar isOpen={isPanelOpen} onToggle={handleVisibilityToggle} />
      <Collapsible
        title="Datasets"
        isOpen={openSections.includes('Datasets')}
        onToggle={useCallback(
          (isOpen: boolean) => handleSectionToggle('Datasets', isOpen),
          [handleSectionToggle]
        )}
        hideIndentGuide={false}
        onClick={() => setActiveSection('dataset')}
        data-cy="dataset-collapsible"
      >
        <Dataset />
      </Collapsible>
      <Collapsible
        title="Trajectories"
        isOpen={openSections.includes('Trajectories')}
        onToggle={useCallback(
          (isOpen: boolean) => handleSectionToggle('Trajectories', isOpen),
          [handleSectionToggle]
        )}
        hideIndentGuide={true}
        onClick={() => setActiveSection('trajectory')}
      >
        <TrajectoryList
          trajectories={trajectories}
          trajectoryNames={trajectoryNames}
          hiddenTrajectories={hiddenTrajectories}
          selectedTrajectoryId={selectedTrajectoryId}
          onToggleHide={onToggleHide}
          onDelete={onDelete}
          onSelectTrajectory={onSelectTrajectory}
          onTrajectoryNameChange={handleTrajectoryNameChange}
        />
      </Collapsible>
      <Collapsible
        title="Pose"
        isOpen={openSections.includes('Pose')}
        onToggle={useCallback(
          (isOpen: boolean) => handleSectionToggle('Pose', isOpen),
          [handleSectionToggle]
        )}
        hideIndentGuide={true}
        onClick={() => setActiveSection('pose')}
      >
        <PoseControl
          setVisibleLimbs={setVisibleLimbs}
          setOpacity={setOpacity}
        />
      </Collapsible>
      <Collapsible
        title="Video"
        isOpen={openSections.includes('Video')}
        onToggle={useCallback(
          (isOpen: boolean) => handleSectionToggle('Video', isOpen),
          [handleSectionToggle]
        )}
        hideIndentGuide={true}
        onClick={() => setActiveSection('video')}
      >
        <VideoPreview setCurrentFrame={setCurrentFrame} />
      </Collapsible>
      <Collapsible
        title="Prompt"
        hideIndentGuide={true}
        onClick={() => setActiveSection('prompt')}
      >
        <Prompt />
      </Collapsible>
      <Collapsible
        title="Justin"
        imagePath={'https://avatars.githubusercontent.com/u/3211697?v=4'}
        height={'40px'}
        hideArrow={true}
        hideIndentGuide={true}
        onFocusHighlight={true}
        isActive={activeSection === 'account'}
        onClick={() => setActiveSection('account')}
      >
        <Account />
      </Collapsible>
    </div>
  );
};

export default ControlPanel;
