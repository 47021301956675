import React, { useRef, useState, useEffect } from 'react';
import { useTrajectoryStore } from '../../../../stores/trajectoryStore';
import './PromptEditor.css';
import { FaPlay, FaPause } from 'react-icons/fa';

const PromptEditor: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const activeVideoUrl = useTrajectoryStore((state) => state.activeVideoUrl);
  const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [clickedPosition, setClickedPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [markers, setMarkers] = useState<
    Array<{
      x: number;
      y: number;
      frame: number;
    }>
  >([]);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      if (videoRef.current) {
        const dimensions = {
          width: videoRef.current.videoWidth,
          height: videoRef.current.videoHeight,
        };
        console.log('Video native dimensions:', dimensions);
        console.log('Video element dimensions:', {
          width: videoRef.current.offsetWidth,
          height: videoRef.current.offsetHeight,
        });
        setVideoDimensions({
          width: videoRef.current.videoWidth,
          height: videoRef.current.videoHeight,
        });
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', updateDimensions);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          'loadedmetadata',
          updateDimensions
        );
      }
    };
  }, []);

  const handleClick = async (e: React.MouseEvent<HTMLVideoElement>) => {
    if (videoRef.current) {
      const rect = videoRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      console.log('Raw click coordinates:', { x, y });

      // Calculate relative coordinates
      const relativeX = x / rect.width;
      const relativeY = y / rect.height;

      // Calculate coordinates relative to original video dimensions
      const videoX = Math.round(relativeX * videoDimensions.width);
      const videoY = Math.round(relativeY * videoDimensions.height);
      const frame = videoRef.current.currentTime;

      console.log('Raw click coordinates:', { videoX, videoY });

      setMarkers((prev) => [
        ...prev,
        {
          x: relativeX * 100,
          y: relativeY * 100,
          frame,
        },
      ]);

      setClickedPosition({ x: relativeX * 100, y: relativeY * 100 });
    }
  };

  const handleTimeUpdate = () => {
    if (!isDragging && videoRef.current) {
      const progress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  const updateProgress = (clientX: number) => {
    if (videoRef.current) {
      const scrubber = document.querySelector('.scrubber') as HTMLDivElement;
      const rect = scrubber.getBoundingClientRect();
      const percentage = Math.min(
        Math.max(((clientX - rect.left) / rect.width) * 100, 0),
        100
      );
      const time = (videoRef.current.duration / 100) * percentage;
      videoRef.current.currentTime = time;
      setProgress(percentage);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent click from bubbling to scrubber
    setIsDragging(true);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      e.preventDefault();
      updateProgress(e.clientX);
    }
  };

  const handleScrubberClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) {
      // Only handle direct clicks, not drag releases
      updateProgress(e.clientX);
    }
  };

  useEffect(() => {
    const handleGlobalMouseUp = () => setIsDragging(false);
    const handleGlobalMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        updateProgress(e.clientX);
      }
    };

    document.addEventListener('mousemove', handleGlobalMouseMove);
    document.addEventListener('mouseup', handleGlobalMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleGlobalMouseMove);
      document.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isDragging]);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="video-container">
      <div className="video-wrapper">
        <video
          ref={videoRef}
          src={activeVideoUrl}
          onClick={handleClick}
          onTimeUpdate={handleTimeUpdate}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          className="full-screen-video"
        >
          <track kind="captions" />
        </video>
        {clickedPosition && (
          <div
            className="marker"
            style={{
              left: `${clickedPosition.x}%`,
              top: `${clickedPosition.y}%`,
            }}
          />
        )}
      </div>
      <div className="controls">
        <button className="play-button" onClick={togglePlay}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <div className="scrubber" onClick={handleScrubberClick}>
          <div className="progress" style={{ width: `${progress}%` }}>
            <div className="handle" onMouseDown={handleMouseDown} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptEditor;
