import { Text, Billboard } from '@react-three/drei';
import React, { useMemo, useCallback } from 'react';

/**
 * Renders a single data point with an optional label.
 *
 * @param {Object} props - The component props.
 * @param {[number, number, number]} props.position - The 3D position of the data point.
 * @param {string} props.color - The color of the data point.
 * @param {string} props.label - The label to display next to the data point.
 * @param {number} props.index - The index of the data point.
 * @param {(index: number | null) => void} props.onHover - A callback function to handle hover events on the data point.
 * @returns {JSX.Element} - The rendered data point with optional label.
 */
export function Point({
  position,
  color,
  label,
  fontSize,
  index,
  onHover,
  onClick,
  trajectoryId,
}: {
  position: [number, number, number];
  color: string;
  label: string;
  fontSize: number;
  index: number;
  onHover: (index: number | null) => void;
  onClick: (trajectoryId: number) => void;
  trajectoryId: number;
}) {
  const billboardPosition = useMemo(
    () =>
      [position[0] + 0.02, position[1] + 0.02, position[2]] as [
        number,
        number,
        number,
      ],
    [position]
  );

  const handlePointerOver = useCallback(() => onHover(index), [onHover, index]);
  const handlePointerOut = useCallback(() => onHover(null), [onHover]);
  const handleClick = useCallback(
    () => onClick(trajectoryId),
    [onClick, trajectoryId]
  );

  return (
    <>
      <mesh
        position={position}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        onClick={handleClick}
      >
        <sphereGeometry args={[0.03, 32, 32]} />
        <meshBasicMaterial color={color} />
      </mesh>
      {label && (
        <Billboard position={billboardPosition}>
          <Text fontSize={fontSize} color={color}>
            {label}
          </Text>
        </Billboard>
      )}
    </>
  );
}
