/**
 * A mapping of body part indices to their corresponding names.
 */
export const jointMap: { [key: number]: string } = {
  0: 'pelvis',
  1: 'left hip',
  2: 'left knee',
  3: 'left ankle',
  4: 'right hip',
  5: 'right knee',
  6: 'right ankle',
  7: 'abdomen',
  8: 'suprasternal notch',
  9: 'nose',
  10: 'skull vertex',
  11: 'right shoulder',
  12: 'right elbow',
  13: 'right wrist',
  14: 'left shoulder',
  15: 'left elbow',
  16: 'left wrist',
};

export const limbMap: { [key: number]: [number, number] } = {
  0: [0, 7], // abdomen
  1: [7, 8], // chest
  2: [8, 9], // neck
  3: [9, 10], // face
  4: [8, 11], // leftCollar
  5: [11, 12], // leftBicep
  6: [12, 13], // leftForearm
  7: [0, 4], // leftHip
  8: [4, 5], // leftThigh
  9: [5, 6], // leftShin
  10: [8, 14], // rightCollar
  11: [14, 15], // rightBicep
  12: [15, 16], // rightForearm
  13: [0, 1], // rightHip
  14: [1, 2], // rightThigh
  15: [2, 3], // rightShin
};

export const leftLimbs = [
  [8, 11], // Left collar bone
  [11, 12], // Left bicep
  [12, 13], // Left forearm
  [0, 4], // Left hip
  [4, 5], // Left thigh
  [5, 6], // Left shin
];

export const rightLimbs = [
  [8, 14], // Right collar bone
  [14, 15], // Right bicep
  [15, 16], // Right forearm
  [0, 1], // Right hip
  [1, 2], // Right thigh
  [2, 3], // Right shin
];

export const colorMap = {
  mid: '#000000',
  left: '#ff8c04',
  right: '#1cd0d8',
};
