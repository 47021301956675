import React, { useState } from 'react';
import './Prompt.css';
import { useTrajectoryStore } from '../../../../stores/trajectoryStore';
import { VisualMode } from '../../../../utils/types';

interface PromptProps {
  imageUrl?: string;
}

const Prompt: React.FC<PromptProps> = ({ imageUrl }) => {
  const setVisualMode = useTrajectoryStore((state) => state.setVisualMode);
  const visualMode = useTrajectoryStore((state) => state.visualMode);
  const activeVideoUrl = useTrajectoryStore((state) => state.activeVideoUrl);

  const handlePromptEditor = (mode: VisualMode) => {
    setVisualMode(mode);
  };

  const renderButton = () => {
    if (visualMode === '3d') {
      return (
        <button
          className="prompt-editor-button"
          onClick={() => handlePromptEditor('video')}
          disabled={!activeVideoUrl}
        >
          Prompt Editor
        </button>
      );
    }
    return (
      <button
        className="prompt-editor-button"
        onClick={() => handlePromptEditor('3d')}
        disabled={!activeVideoUrl}
      >
        3D Visualizer
      </button>
    );
  };

  return (
    <>
      {visualMode === '3d' && (
        <div className="prompt-container">
          {imageUrl ? (
            <img src={imageUrl} alt="Prompt" className="prompt-image" />
          ) : (
            renderButton()
          )}
        </div>
      )}
      {visualMode === 'video' && (
        <div className="prompt-container">{renderButton()}</div>
      )}
    </>
  );
};

export default Prompt;
