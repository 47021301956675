import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import './Account.css';

const Account: React.FC = React.memo(() => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="account-container">
      <ul className="account-menu">
        <li onClick={handleLogout}>Logout</li>
      </ul>
    </div>
  );
});

export default Account;
