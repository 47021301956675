import React, { useRef, useEffect, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { throttle } from 'lodash';

/**
 * Provides camera controls for the 3D scene, allowing the user to rotate, pan, and zoom the camera.
 *
 * @returns {JSX.Element} - The rendered OrbitControls component.
 */
export const CameraControls = React.memo(function CameraControls() {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef<any>(null);
  const [isPanning, setIsPanning] = useState(false);
  const lastMousePosition = useRef({ x: 0, y: 0 });
  const [isCommandKeyPressed, setIsCommandKeyPressed] = useState(false);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  useEffect(() => {
    if (controls.current) {
      controls.current.enableRotate = true;
      controls.current.enablePan = false; // Disable default panning
      controls.current.enableZoom = true;
      controls.current.minPolarAngle = 0;
      controls.current.maxPolarAngle = Math.PI / 2;
      controls.current.minAzimuthAngle = -Infinity;
      controls.current.maxAzimuthAngle = Infinity;
    }

    // Update cursor based on shift and panning states
    const updateCursor = () => {
      if (isPanning) {
        domElement.style.cursor = 'grabbing'; // Shift + dragging
      } else if (isShiftPressed) {
        domElement.style.cursor = 'grab'; // Shift pressed
      } else {
        domElement.style.cursor = 'default'; // Normal state
      }
    };
    updateCursor();

    return () => {
      domElement.style.cursor = 'default';
    };
  }, [isPanning, isShiftPressed]);

  useEffect(() => {
    domElement.addEventListener('mousedown', handleMouseDown);
    domElement.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      domElement.removeEventListener('mousedown', handleMouseDown);
      domElement.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [domElement, isPanning]);

  useFrame(() => {
    if (controls.current) {
      controls.current.update();
    }
  });

  /**
   * Handles the mouse down event for the visualizer, enabling panning mode if the shift key is pressed.
   * @param {MouseEvent} event - The mouse down event object.
   * @returns {void}
   */
  const handleMouseDown = (event: MouseEvent) => {
    if (event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      setIsPanning(true);
      lastMousePosition.current = { x: event.clientX, y: event.clientY };
      if (controls.current) {
        controls.current.enableRotate = false;
      }
    }
  };

  /**
   * Handles the mouse up event for the visualizer.
   * @param {void} - This function takes no parameters.
   * @returns {void} - This function does not return anything.
   */
  const handleMouseUp = () => {
    setIsPanning(false);
    if (controls.current) {
      controls.current.enableRotate = true;
    }
  };

  /**
   * Handles the mouse move event for the visualizer, allowing the user to pan the camera.
   * @param {MouseEvent} event - The mouse move event object.
   * @returns {void}
   */
  const handleMouseMove = (event: MouseEvent) => {
    if (isCommandKeyPressed) {
      return; // Ignore mouse movement when command key is pressed
    }

    if (isPanning && controls.current) {
      const deltaX = event.clientX - lastMousePosition.current.x;
      const deltaY = event.clientY - lastMousePosition.current.y;

      // Adjust these values to control panning speed
      const panSpeed = 0.01;

      // Calculate the right and up vectors of the camera
      const right = new THREE.Vector3();
      const up = new THREE.Vector3(0, 1, 0);
      camera.getWorldDirection(right);
      right.cross(up).normalize();

      // Calculate the translation
      const translation = new THREE.Vector3()
        .addScaledVector(right, -deltaX * panSpeed)
        .addScaledVector(up, deltaY * panSpeed);

      // Apply the translation to the orbit controls target
      controls.current.target.add(translation);
      camera.position.add(translation);

      lastMousePosition.current = { x: event.clientX, y: event.clientY };
    }
  };

  /**
   * Handles the key down event for the visualizer.
   * @param {KeyboardEvent} event - The keyboard event object.
   * @returns {void}
   */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.shiftKey) {
      setIsShiftPressed(true);
      if (controls.current) {
        controls.current.enableRotate = false;
      }
    }

    if (event.metaKey || event.ctrlKey) {
      setIsCommandKeyPressed(true);
    }
  };

  /**
   * Handles the key up event for the visualizer.
   * @param {KeyboardEvent} event - The keyboard event object.
   */
  const handleKeyUp = (event: KeyboardEvent) => {
    if (!event.shiftKey) {
      setIsShiftPressed(false);
      if (controls.current) {
        controls.current.enableRotate = true;
      }
    }

    if (!event.metaKey && !event.ctrlKey) {
      setIsCommandKeyPressed(false);
    }
  };

  useEffect(() => {
    const handleMouseMoveThrottled = throttle(handleMouseMove, 100);
    window.addEventListener('mousemove', handleMouseMoveThrottled);
    return () =>
      window.removeEventListener('mousemove', handleMouseMoveThrottled);
  }, [handleMouseMove]);

  return <OrbitControls ref={controls} args={[camera, domElement]} />;
});
