import React, { ReactNode, useState, useMemo, useCallback } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import './Collapsible.css';

interface CollapsibleProps {
  title?: string;
  children: ReactNode;
  onToggle?: (isOpen: boolean) => void;
  imagePath?: string;
  onClick?: () => void;
  headerButton?: React.ReactNode;
  onTitleChange?: (newTitle: string) => void;
  isOpen?: boolean;
  height?: string;
  hideArrow?: boolean;
  isActive?: boolean;
  hideIndentGuide?: boolean;
  editableTitle?: boolean;
  onFocusHighlight?: boolean;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  title = '',
  children,
  imagePath,
  onClick,
  headerButton,
  onTitleChange,
  isOpen = true,
  height = 'auto',
  hideArrow = false,
  isActive = false,
  hideIndentGuide = false,
  editableTitle = false,
  onFocusHighlight = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!isOpen);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);

  const icon = useMemo(
    () => (isCollapsed ? <FaChevronRight /> : <FaChevronDown />),
    [isCollapsed]
  );

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleClick = (e: React.MouseEvent) => {
    onClick?.();
  };

  const handleArrowClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleCollapse();
  };

  const handleTitleDoubleClick = (e: React.MouseEvent) => {
    if (editableTitle) {
      e.stopPropagation();
      setIsEditing(true);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleConfirm = () => {
    onTitleChange?.(editedTitle);
    setIsEditing(false);
  };

  return (
    <div
      className={`collapsible ${isActive ? 'active' : ''}`}
      style={{
        height,
        backgroundColor:
          onFocusHighlight && isActive
            ? 'rgba(255, 255, 255, 0.1)'
            : 'transparent',
      }}
      onClick={handleClick}
    >
      {!isCollapsed && !isActive && !hideArrow && !hideIndentGuide && (
        <div
          className="indent-guide"
          style={{
            position: 'absolute',
            left: '14px',
            top: '30px',
            bottom: '8px',
            width: '1px',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        />
      )}
      <div className="collapsible-header" onClick={handleClick}>
        <div className="collapsible-header-left">
          {!hideArrow && (
            <span onClick={handleArrowClick} data-cy={title.toLowerCase()}>
              {icon}
            </span>
          )}
          {imagePath && (
            <img src={imagePath} alt="" className="collapsible-icon" />
          )}
          {title &&
            (isEditing ? (
              <input
                value={editedTitle}
                onChange={handleTitleChange}
                onKeyDown={(e) => e.key === 'Enter' && handleTitleConfirm()}
                autoFocus
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <h3 onDoubleClick={handleTitleDoubleClick}>{title}</h3>
            ))}
        </div>
        <div className="collapsible-header-right">
          {isEditing && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleTitleConfirm();
              }}
              className="confirm-edit-button"
            >
              ✓
            </button>
          )}
          {headerButton}
        </div>
      </div>
      {!isCollapsed && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

export default React.memo(Collapsible);
