import { Api } from '../services/Api';
import { supabase } from './supabase';

export const verifyAccess = async (oauthToken: string): Promise<boolean> => {
  try {
    const response = await Api.post(
      '/v0/auth/check-access',
      { token: oauthToken },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.status === 200;
  } catch (error) {
    console.error('Error verifying access:', error);
    return false;
  }
};

export const initAuthListener = (
  onAuthChange: (event: string, session: any) => void
) => {
  const {
    data: { subscription },
  } = supabase.auth.onAuthStateChange((event, session) => {
    onAuthChange(event, session);
  });

  return () => {
    subscription.unsubscribe();
  };
};

export const refreshAccessToken = async (
  setIsAuthenticated: (isAuthenticated: boolean) => void
) => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    setIsAuthenticated(false);
    return;
  }

  const { data, error } = await supabase.auth.refreshSession({
    refresh_token: refreshToken,
  });

  if (error) {
    console.error('Error refreshing access token:', error);
    setIsAuthenticated(false);
    return;
  }

  if (data.session) {
    localStorage.setItem('oauthToken', data.session.access_token || '');
    localStorage.setItem('refreshToken', data.session.refresh_token || '');
    setIsAuthenticated(true);
  } else {
    setIsAuthenticated(false);
  }
};

export const signInWithGoogle = async (): Promise<{
  url?: string;
  error?: string;
}> => {
  try {
    const { data, error: supabaseError } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/auth/callback`,
        skipBrowserRedirect: true,
      },
    });

    if (supabaseError) {
      console.error('Google Login Failed:', supabaseError);
      return {
        error: 'Unable to sign in at this time. Please try again later.',
      };
    } else if (data && data.url) {
      return { url: data.url };
    }
  } catch (error: unknown) {
    console.error('Error during login process:', error);
    if (error instanceof Error) {
      console.error('Error message:', error.message);
    }
    if (typeof error === 'object' && error !== null && 'response' in error) {
      console.error('Error response:', (error as any).response?.data);
    }
    return { error: 'Unable to sign in at this time. Please try again later.' };
  }
  return {};
};
