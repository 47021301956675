/**
 * RouterComponent.tsx
 *
 * This component handles the routing for the application using React Router.
 * It defines the navigational routes and the associated views or components
 * that should be rendered for each route.
 *
 * The component uses React's lazy loading and Suspense to dynamically load
 * views, which helps in optimizing the application's performance by splitting
 * the code at logical points and loading parts of it only when needed.
 *
 * The 'HomeView' component is lazy-loaded and rendered when the user navigates
 * to the root path ('/').
 *
 * Usage:
 * This component should be used at a top-level in the application to define the
 * main routing structure.
 *
 * Example:
 * <RouterComponent />
 */

import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import VisualizerView from '../views/editor/VisualizerView';
import AuthCallback from '../views/login/component/auth/AuthCallback';
import Login from '../views/login/Login';
import { useNavigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { useAuth } from '../hooks/useAuth';

function RouterComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, loading } = useAuth();

  useEffect(() => {
    const handleUnauthorized = () => {
      navigate('/login');
    };

    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <VisualizerView />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default RouterComponent;
