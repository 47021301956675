import React from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import './VisibilityBar.css';

interface VisibilityBarProps {
  isOpen: boolean;
  onToggle: () => void;
}

const VisibilityBar: React.FC<VisibilityBarProps> = ({ isOpen, onToggle }) => {
  return (
    <div className="visibility-bar">
      <button onClick={onToggle} className="visibility-toggle">
        {isOpen ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
      </button>
    </div>
  );
};

export default VisibilityBar;
