import { useState } from 'react';
import './PoseControl.css';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { limbMap } from '../../visualizer/PoseLandmarkMap';

interface Limb {
  id: string;
  name: string;
}

const defaultLimbs: Limb[] = [
  { id: 'abdomen', name: 'Abdomen' },
  { id: 'chest', name: 'Chest' },
  { id: 'neck', name: 'Neck' },
  { id: 'face', name: 'Face' },

  // Left side
  { id: 'leftCollar', name: 'Left Collar' },
  { id: 'leftBicep', name: 'Left Bicep' },
  { id: 'leftForearm', name: 'Left Forearm' },
  { id: 'leftHip', name: 'Left Hip' },
  { id: 'leftThigh', name: 'Left Thigh' },
  { id: 'leftShin', name: 'Left Shin' },

  // Right side
  { id: 'rightCollar', name: 'Right Collar' },
  { id: 'rightBicep', name: 'Right Bicep' },
  { id: 'rightForearm', name: 'Right Forearm' },
  { id: 'rightHip', name: 'Right Hip' },
  { id: 'rightThigh', name: 'Right Thigh' },
  { id: 'rightShin', name: 'Right Shin' },
];

interface PoseControlProps {
  setVisibleLimbs: React.Dispatch<React.SetStateAction<Map<number, boolean>>>;
  setOpacity: React.Dispatch<React.SetStateAction<number>>;
}

const PoseControl: React.FC<PoseControlProps> = ({
  setVisibleLimbs,
  setOpacity,
}) => {
  const [visibleLimbIds, setVisibleLimbIds] = useState<Map<number, boolean>>(
    new Map(Object.keys(limbMap).map((key) => [Number(key), true]))
  );

  const toggleLimb = (limbIndex: number) => {
    setVisibleLimbIds((prevIds) => {
      const newIds = new Map(prevIds);
      newIds.set(limbIndex, !newIds.get(limbIndex));
      setVisibleLimbs(newIds);
      return newIds;
    });
  };

  return (
    <div className="pose-control">
      <div className="limb-opacity">
        <span>Opacity</span>
        <input
          type="number"
          min="0"
          max="1"
          step="0.1"
          defaultValue="1"
          onChange={(e) => {
            setOpacity(parseFloat(e.target.value));
          }}
        />
      </div>
      {defaultLimbs.map((limb, index) => (
        <div
          key={limb.id}
          className="limb-row"
          onClick={() => toggleLimb(index)}
        >
          <span>{limb.name}</span>
          {visibleLimbIds.get(index) ? (
            <VisibilityIcon className="visibility-icon" />
          ) : (
            <VisibilityOffIcon className="visibility-icon" />
          )}
        </div>
      ))}
    </div>
  );
};

export default PoseControl;
