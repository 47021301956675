import './VideoPreview.css';
import { useTrajectoryStore } from '../../../../stores/trajectoryStore';

interface VideoPreviewProps {
  setCurrentFrame: (frame: number) => void;
}

export function VideoPreview({ setCurrentFrame }: VideoPreviewProps) {
  const { activeVideoUrl } = useTrajectoryStore();

  const handleTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    const video = e.target as HTMLVideoElement;
    const fps = 30;
    const currentFrame = Math.floor(video.currentTime * fps);
    setCurrentFrame(currentFrame);
  };

  if (!activeVideoUrl) return <div>No file selected</div>;

  return (
    <video controls className="video-preview" onTimeUpdate={handleTimeUpdate}>
      <source src={activeVideoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
